@import '../main.scss';
@import '@nerdwallet/base-styles/styles.scss';

.aligned {
  @include margin-horizontal-auto;
  @include padding-horizontal-4--wide;
  /* stylelint-disable-next-line unit-disallowed-list */
  max-width: 950px;

  @media #{$mobile-only} {
    @include margin-horizontal-3;
  }

  @media #{$very-wide-layout} {
    /* stylelint-disable-next-line unit-disallowed-list */
    max-width: 1220px;
  }
}

@mixin grid() {
  display: grid;
  /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
  column-gap: $var-grid-gap--wide;
  /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
  grid-template-columns: repeat(12, 1fr);
}

.centered-section-spacing {
  /* stylelint-disable-next-line unit-disallowed-list, property-disallowed-list */
  padding-left: 64px;
  /* stylelint-disable-next-line unit-disallowed-list, property-disallowed-list */
  padding-right: 64px;

  @media #{$breakpoint-not-wide} {
    @include padding-horizontal-0;
  }
}

.grid-odd {
  display: grid;
  /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
  column-gap: $var-grid-gap--wide;
  /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
  grid-template-columns: repeat(11, 1fr);
}
