@import '../../../styles/front-page.scss';

// override $max-tablet to match 1024px breakpoint
$screen-medium-max: 1024px;
$max-tablet: '(max-width: #{$screen-medium-max})';

.container {
  position: fixed;
  bottom: 0;
  height: 96px;
  width: 100%;
  background-color: #c8e4fe;
  box-shadow:
    0px 1px 4px 0px #64666a5c,
    0px 4px 8px 0px #64666a1a;
  transition: all 0.3s cubic-bezier(0.23, 1, 0.32, 1);
  transform-origin: bottom;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media #{$max-tablet} {
    height: 86px;
  }

  @media #{$mobile-only} {
    height: 76px;
  }
}

.scrolled {
  height: 56px;
  cursor: pointer;
  z-index: 100;

  // mobile browsers like Chrome on Android and Safari on iOS, UI elements can
  // get hidden behind system UI components (e.g., the notch, status bar, home bar)
  &.isIOSChromeOrFirefox {
    height: 68px;
    padding-bottom: 37px;
    padding-top: 23px;
    padding-bottom: env(safe-area-inset-bottom, 34px);
  }
}

.belugaContainer {
  transition: max-width 400ms cubic-bezier(0.23, 1, 0.32, 1);
  max-width: 100%;

  @media #{$max-tablet} {
    height: 86px;
    width: 174px;
    margin-bottom: 0;
  }
  @media #{$mobile-only} {
    display: none;
  }
}

.belugaContainerCollapsed {
  transform: translate(-100%, 100%);
  max-width: 0%;
}

.belugaMobileContainer {
  display: none;
  transition: all 0.3s cubic-bezier(0.23, 1, 0.32, 1);
  @media #{$mobile-only} {
    display: block;
    position: absolute;
    bottom: -10px;
    right: 0;
  }
}

.belugaImageDesktop {
  display: block;
  height: 96px;
  width: 229px;

  @media #{$max-tablet} {
    display: none;
  }
}

.belugaImageTablet {
  display: none;
  width: 174px;
  height: 86px;

  @media #{$tablet-only} {
    display: block;
  }
}

.belugaMobileSlideIn {
  transform: translateY(50px);
}

.text {
  display: flex;
  flex-wrap: wrap;
  font-family: Gotham;
  padding: 0 10px;
  flex: 1;
  justify-content: center;

  h5 {
    font-size: 24px;
  }

  @media #{$max-tablet} {
    flex-direction: column;
    ::first-letter {
      text-transform: uppercase;
    }
    h5 {
      font-size: 18px;
    }
  }

  @media #{$mobile-only} {
    h5 {
      font-size: 16px;
    }
  }
}

.desktopText {
  @media #{$max-tablet} {
    display: none;
  }
}

.MobileText {
  display: none;
  @media #{$max-tablet} {
    display: block;
  }
}

.tabletCta {
  display: none;
  @media #{$max-tablet} {
    margin-top: 4px;
    font-size: 14px;
    display: block;
    color: #006642 !important;
    font-weight: bold;
    text-transform: uppercase;
    &:hover,
    &:active,
    &:visited,
    &:focus {
      color: #006642;
      text-decoration: none;
    }
    svg {
      margin-left: 8px;
    }
  }

  @media #{$mobile-only} {
    font-size: 12px;
  }
}

.tabletCtaCollapsed {
  margin-top: 0;
}

.textContainerCollapsed {
  padding-left: 16px;
  justify-content: flex-start;

  h5 {
    font-size: 16px;
  }

  @media #{$max-tablet} {
    padding-left: 20px;
  }

  @media #{$mobile-only} {
    h5 {
      font-size: 15px;
    }
  }
}

.QRCodeImageContainer {
  margin-right: 126px;
  transition:
    visibility 300ms ease-in-out,
    opacity 400ms ease-in-out;
  opacity: 1;

  @media #{$max-tablet} {
    display: none;
  }
}

.QRcodeContainerCollapsed {
  transition:
    visibility 100ms ease-in-out,
    opacity 50ms ease-in-out;
  opacity: 0;
}

.closeButtonContainer {
  align-self: flex-start;
  position: relative;
  z-index: 1;
}

.chevronDown {
  cursor: pointer;
  padding: 10px;
  border: none;
  background: transparent;
  @media #{$mobile-only} {
    padding: 8px;
    svg {
      width: 16px;
    }
  }
}

.adjustCloseIcon {
  align-self: center;
}
