@import '@nerdwallet/base-styles/styles';
@import './react-media-queries';
@import './util-styles/antialias';
@import './front-page-header-variables';

@mixin homepage-component-wrapper() {
  /* stylelint-disable-next-line property-disallowed-list */
  padding-left: var(--global-nav-content-gutter);
  /* stylelint-disable-next-line property-disallowed-list */
  padding-right: var(--global-nav-gutter);

  @media #{$front-page-breakpoint-not-desktop} {
    @include padding-horizontal-4;
  }

  @media #{$front-page-breakpoint-mobile-small} {
    @include padding-horizontal-3;
  }

  @media #{$front-page-breakpoint-small-desktop} {
    /* stylelint-disable-next-line property-disallowed-list */
    padding-left: var(--global-nav-gutter);
  }

  @media #{$front-page-breakpoint-superwide-desktop} {
    @include padding-left-4;
  }
}

.front-page-section-header {
  /* stylelint-disable-next-line unit-disallowed-list, property-disallowed-list */
  font: 800 30px/36px GothamDisplay;
  text-align: center;
  @extend .antialias;

  @media #{$min-tablet} {
    /* stylelint-disable-next-line unit-disallowed-list, property-disallowed-list */
    font: 800 36px/36px GothamDisplay;
  }
}

.front-page-section-subtitle {
  text-align: center;
  /* stylelint-disable-next-line unit-disallowed-list, property-disallowed-list */
  padding: 10px 14px 48px 14px;

  /* stylelint-disable-next-line unit-disallowed-list, property-disallowed-list */
  font: 400 16px/24px GothamDisplay;
  /* stylelint-disable-next-line property-disallowed-list */
  color: $neutral-dark;
  /* stylelint-disable-next-line property-disallowed-list */
  letter-spacing: -0.02em;
  @extend .antialias;

  @media #{$min-tablet} {
    /* stylelint-disable-next-line unit-disallowed-list, property-disallowed-list */
    font-size: 18px;
    /* stylelint-disable-next-line unit-disallowed-list, property-disallowed-list */
    padding: 14px 14px 51px 24px;
  }
}

.divider-line {
  /* stylelint-disable-next-line property-disallowed-list */
  border-top: 1px solid $neutral-light;
  /* stylelint-disable-next-line unit-disallowed-list */
  height: 0px;
}

.hero {
  &__auth-button {
    /* stylelint-disable-next-line unit-disallowed-list */
    width: 160px;
  }
}

.front-page-gradient {
  /* stylelint-disable color-no-hex */
  /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
  background-image: linear-gradient(
    180deg,
    #eef7ff 0%,
    rgba(238, 247, 255, 0) 100%
  );
  /* stylelint-enable color-no-hex */
}

// v3

.v3 {
  &__title {
    /* stylelint-disable-next-line property-disallowed-list */
    font-family: Chronicle Display;
    /* stylelint-disable-next-line property-disallowed-list */
    font-weight: 100;
    @include margin-vertical-3;
    @include margin-horizontal-0;
  }
}

.carousel .slick-slider .slick-list .slick-track .slick-slide {
  @media #{$mobile-only} {
    @include display-flex;
    @include justify-content-center;
  }
}
.nerd-carousel .carousel .slick-slider .slick-list .slick-track .slick-slide {
  @media #{$mobile-only} {
    > div {
      @include flex-1;
    }
  }
}
.carousel .slick-slider .social-impact {
  li button {
    /* stylelint-disable-next-line unit-disallowed-list */
    width: 16px;
  }
  li {
    button::before {
      /* stylelint-disable-next-line unit-disallowed-list */
      width: 16px;
      transform: scale(1.5);
      /* stylelint-disable-next-line property-disallowed-list, color-no-hex */
      color: #9d9da1;
    }
  }
  li.slick-active {
    button::before {
      /* stylelint-disable-next-line property-disallowed-list, color-no-hex */
      color: #3d4045;
    }
  }
}

.footerDisclaimer {
  @include text-align-center;
  @include margin-horizontal-auto;
  @include padding-horizontal-3;
  @include color-neutral-darker-2;
  max-width: 760px;
  font-size: 0.8125rem;
  margin-bottom: 4rem;
}

// @import '../components/front-page/cc-best/cc-best';
// @import '../components/front-page/security/security';
// @import '../components/front-page/registration-module/registration-module';
