@import '../../../styles/util-styles/aligned';

.carousel .testimonial-carousel .slick-dots {
  display: none !important;

  @media #{$mobile-only} {
    display: flex !important;
  }

  button {
    width: 16px;
    &::before {
      font-size: 1.8rem;
      width: 16px;
      color: #267f4c;
    }
  }

  .slick-active {
    button {
      &::before {
        color: #006642;
      }
    }
  }
}
