@import '../../../styles/util-styles/aligned';

.backgroundContainer {
  padding-top: 64px;
  padding-bottom: 96px;

  // apply the background grid pattern, dismissing the need for an image
  background-color: #81d6b8;
  background-image: linear-gradient(0deg, #57bc98 1px, transparent 1px),
    linear-gradient(90deg, #57bc98 1px, transparent 1px);
  background-size: 20px 20px; /* Adjust grid cell size */

  @media #{$max-tablet} {
    padding-top: 48px;
    padding-bottom: 80px;
    background-color: #b7ecda;
    // Line color is  #81d6b8 with 40% opactity
    background-image: linear-gradient(
        0deg,
        rgba(129, 214, 184, 0.4) 1px,
        transparent 1px
      ),
      linear-gradient(90deg, rgba(129, 214, 184, 0.4) 1px, transparent 1px);
    background-size: 8px 8px; /* Adjust grid cell size */
  }

  @media #{$mobile-only} {
    padding-top: 32px;
  }
}

.desktopArticleContainer {
  padding: 48px;
}

.desktopContainer {
  @media #{$max-tablet} {
    display: none;
  }
}

.mobileContainer {
  display: none;
  padding-top: 30px;
  @media #{$tablet-only} {
    display: block;
  }
  @media #{$mobile-only} {
    display: block;
  }
}

.articleHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media #{$max-tablet} {
    margin-bottom: 32px;
  }
}

.articleHeaderTitle {
  color: #00482f;
  font-size: 24px;
  @media #{$max-tablet} {
    font-size: 18px;
  }

  @media #{$mobile-only} {
    font-size: 20px;
  }
}

.articleLinks {
  &:hover {
    text-decoration: none;
  }
  &:visited {
    color: unset;
  }
  &:active {
    color: unset;
  }
}

.articleHeaderLink {
  @media #{$max-tablet} {
    display: none;
  }

  color: #008254;
  font-size: 16px;
  letter-spacing: 0.8px;
  font-weight: 500;
  text-transform: uppercase;
  &:hover {
    color: #008254;
    text-decoration: none;
  }

  &:visited {
    color: #008254;
  }
  &:active {
    color: #008254;
  }
}

.articleCard {
  background-color: #e3faf2;
  display: flex;
  justify-content: space-between;
  border-radius: 16px;
  margin-top: 29px;
  height: 270px;

  @media #{$max-tablet} {
    flex-direction: column;
    height: unset;
    margin: 0;
    padding: 25px 16px;
  }
}

.articleContent {
  width: 50%;
  display: flex;
  flex-direction: column;
  padding: 32px;
  justify-content: space-between;

  @media #{$max-tablet} {
    padding: 0;
    width: 100%;
  }
}

.articleTitle {
  flex: 1;
  color: #00482f;
  font-size: 24px;
  letter-spacing: 0.12px;

  @media #{$max-tablet} {
    font-size: 18px;
  }
}

.articleImageLinkWrapper {
  width: 50%;
  padding-top: 24px;
  padding-right: 32px;
  padding-bottom: 24px;
  display: flex;

  img {
    width: 100%;
    height: 100%;
    border-radius: 16px;
    object-fit: cover;
  }

  @media #{$max-tablet} {
    width: 100%;
    padding: 0;
    align-self: center;
  }
}

.authorInfo {
  display: flex;
  align-items: center;
  @media #{$max-tablet} {
    margin-top: 16px;
  }
}

.authorImage {
  width: 30px;
  height: 30px;
  margin-right: 5px;
  object-fit: cover;
  border-radius: 50%;
}

.authorName {
  font-size: 14px;
  color: #3d4045;
}

.articleMeta {
  margin-top: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #64666a;

  @media #{$max-tablet} {
    padding-bottom: 25px;
  }
}

.articleMetaText {
  font-size: 12px;
  letter-spacing: 0.06px;
}

// carousel
.carouselContainer {
  padding-top: 29px;

  :global(.carousel .articles-carousel button.slick-arrow) {
    background-color: #40ad87;
    box-shadow: 0px 0.8px 1.6px 0px #00482f;
    &:hover {
      background-color: #006642;
    }
    svg {
      fill: #fff;
    }
  }

  :global(.carousel .articles-carousel .slick-dots button) {
    width: 16px;
    &::before {
      font-size: 1.8rem;
      width: 16px;
      color: #81d6b8;
    }
  }

  :global(.carousel .articles-carousel .slick-dots .slick-active button) {
    &::before {
      color: #40ad87;
    }
  }

  :global(.carousel .articles-carousel .slick-list) {
    padding: 2px;
  }

  @media #{$max-tablet} {
    padding-left: 16px;
  }
}

.carouselCard {
  height: 202px;
  background-color: #e3faf2;
  display: flex;
  justify-content: space-between;
  border-radius: 12px;
  width: 333px;
  margin-right: 24px;
  @media #{$max-tablet} {
    width: 231px;
    margin-right: 16px;
    box-shadow:
      0px 1px 4px 0px #64666a5c,
      0px 4px 8px 0px #64666a1a;
  }
}

.carouselContent {
  display: flex;
  flex-direction: column;
  padding: 24px;
  justify-content: space-between;
  width: 100%;

  @media #{$mobile-only} {
    padding: 25px 16px;
  }
}

.carouselTitle {
  flex: 1;
  color: #00482f;
  font-family: Gotham;
  font-size: 18px;
  letter-spacing: 0.09px;
  line-height: normal;
  @media #{$max-tablet} {
    font-size: 16px;
  }
}

.carouselAuthorInfo {
  display: flex;
  align-items: center;
}

.carouselArticleMeta {
  margin-top: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.carouselArticleMetaText {
  font-size: 10px;
  color: #64666a;
}

.mobileCenteredContainer {
  @media #{$min-tablet} {
    padding: 0 16px;
  }
}
